export default [

    {
        group: '/pharmacies',
        model: false,
        icon: 'mdi-animation',
        title: 'GESTIONE',
        children: [
            {
                title: 'Prodotti',
                icon: 'mdi-alert',
                to: 'SingleProductInput',
            }

            // {
            //     title: 'E-Commerce',
            //     icon: 'mdi-alert',
            //     to: 'Products',
            // }
        ]
    }
    // {
    //     group: '/pharmacies',
    //     model: false,
    //     icon: 'mdi-animation',
    //     title: 'FARMACIE',
    //     children: [
    //         {
    //             title: 'Farmacie',
    //             icon: 'mdi-alert',
    //             to: 'list',
    //         }
    //     ]
    // },
    // {
    //     group: '/aggregation',
    //     model: false,
    //     icon: 'mdi-animation',
    //     title: 'AGGREGATION',
    //     children: [
    //         {
    //             title: 'Pipeline',
    //             icon: 'mdi-alert',
    //             to: 'list',
    //         }
    //     ]
    // },
    // {
    //     group: '/datawarehouse',
    //     model: false,
    //     icon: 'mdi-animation',
    //     title: 'DATAWARE',
    //     children: [
    //         {
    //             title: 'MODEL_A',
    //             icon: 'mdi-alert',
    //             to: 'model_a',
    //         },
    //         {
    //             title: 'MODEL_B',
    //             icon: 'mdi-alert',
    //             to: 'model_b',
    //         },
    //         {
    //             title: 'TE001',
    //             icon: 'mdi-alert',
    //             to: 'te001',
    //         },
    //         {
    //             title: 'TE002',
    //             icon: 'mdi-alert',
    //             to: 'te002',
    //         },
    //         {
    //             title: 'TE003',
    //             icon: 'mdi-alert',
    //             to: 'te003',
    //         },
    //         {
    //             title: 'TE004',
    //             icon: 'mdi-alert',
    //             to: 'te004',
    //         },
    //         {
    //             title: 'TE005',
    //             icon: 'mdi-alert',
    //             to: 'te005',
    //         },
    //         {
    //             title: 'TE008',
    //             icon: 'mdi-alert',
    //             to: 'te008',
    //         },
    //         {
    //             title: 'TE009',
    //             icon: 'mdi-alert',
    //             to: 'te009',
    //         },
    //         {
    //             title: 'TR036',
    //             icon: 'mdi-alert',
    //             to: 'tr036',
    //         },
    //         {
    //             title: 'TS067',
    //             icon: 'mdi-alert',
    //             to: 'ts067',
    //         },
    //     ]
    // },
    // {
    //     icon: 'mdi-file',
    //     title: 'Utenti',
    //     to: '/users',
    // },
    // {
    //     icon: 'mdi-file',
    //     title: 'Statistics',
    //     to: '/stats',
    // },
]
