<template>
  <v-app id="inspire">

    <Header v-model="expandOnHover"></Header>

    <v-content >
      <v-container fluid class="page-wrapper">
        <router-view/>
      </v-container>
    </v-content>

    <Sidebar :expand-on-hover.sync="expandOnHover"></Sidebar>

    <Footer></Footer>

  </v-app>

</template>

<script>
import Header from "@/layouts/full-layout/header/Header";
import Sidebar from "@/layouts/full-layout/sidebar/Sidebar";
import Footer from "@/layouts/full-layout/footer/Footer";
export default {
  name: "BaseLayout",
  components: {Footer, Sidebar, Header},
  data: () => ({
    expandOnHover: false
  })
}
</script>

<style scoped>

</style>
